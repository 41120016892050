<template>
  <div class="home">
    <div class="message">Select a period from the menu to view artworks.</div>

  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    exampleTitle: "example title",
   
  }),
  methods: {
    // style() {
    //   return {
    //     backgroundImage: 'url(' + require('@/assets/down-to-the-lake.jpg') + ')'
    //   }
    // }
  }
}
</script>

<style lang="css">
.home {
  /* width: 100vw; */
  /* height: 100vh;
  background-size: cover;
  background-position: 50%;
  position:fixed;
  background-image: url("../assets/Homepage.jpg");
  top:0; */
}
</style>
