<template>
  <div class="home">
    <div class="message">Select a period from the menu to view exhibitions.</div>

  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {

  },
  data: () => ({
    exampleTitle: "example title"
  }),
  methods: {
    
  }
}
</script>

<style lang="css">
.home {
  /* width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: 50%;
  position:fixed;
  top:0; */
}
</style>
